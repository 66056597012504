module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"73e1e1e21e2f0347ea1339b605cc9d8e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Benton Mod Disp Bd","Benton Mod Text Rom","Benton Sans Cond Lt","Benton Sans Cond Bk","Benton Sans Cond Reg","Benton Sans Cond Bd","Benton Sans Bk","Benton Sans Reg","Benton Sans Bd","Benton Sans Bla"],"urls":["//cloud.typenetwork.com/projects/5808/fontface.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
